/* eslint-disable */
// prettier-ignore
export default {
  pages: {
    key: 'title',
    data: [
      { title: 'Access Control', route: { name: 'access-control' }, icon: 'ShieldIcon', isBookmarked: false },
      { title: 'Account Settings', route: { name: 'pages-account-setting' }, icon: 'SettingsIcon', isBookmarked: false },
      { title: 'Advance Card', route: { name: 'card-advance' }, icon: 'CreditCardIcon', isBookmarked: false },
      { title: 'Alerts', route: { name: 'components-alert' }, icon: 'AlertTriangleIcon', isBookmarked: false },
      { title: 'Analytics Cards', route: { name: 'card-analytic' }, icon: 'CreditCardIcon', isBookmarked: false },
      { title: 'Apex Chart', route: { name: 'charts-apex-chart' }, icon: 'PieChartIcon', isBookmarked: false },
      { title: 'Aspect', route: { name: 'components-aspect' }, icon: 'AirplayIcon', isBookmarked: false },
      { title: 'Auto Suggest', route: { name: 'extensions-auto-suggest' }, icon: 'AlignLeftIcon', isBookmarked: false },
      { title: 'Avatar', route: { name: 'components-avatar' }, icon: 'UserIcon', isBookmarked: false },
      { title: 'Badge', route: { name: 'components-badge' }, icon: 'TagIcon', isBookmarked: false },
      { title: 'Basic Card', route: { name: 'card-basic' }, icon: 'CreditCardIcon', isBookmarked: false },
      { title: 'Blog Detail', route: { name: 'pages-blog-detail', params: { id: 1 } }, icon: 'FileTextIcon', isBookmarked: false },
      { title: 'Blog Edit', route: { name: 'pages-blog-edit', params: { id: 1 } }, icon: 'FileTextIcon', isBookmarked: false },
      { title: 'Blog List', route: { name: 'pages-blog-list' }, icon: 'FileTextIcon', isBookmarked: false },
      { title: 'Breadcrumb', route: { name: 'components-breadcrumb' }, icon: 'HomeIcon', isBookmarked: false },
      { title: 'BS Table', route: { name: 'table-bs-table' }, icon: 'GridIcon', isBookmarked: false },
      { title: 'Button Group', route: { name: 'components-button-group' }, icon: 'BoldIcon', isBookmarked: false },
      { title: 'Button Toolbar', route: { name: 'components-button-toolbar' }, icon: 'BoldIcon', isBookmarked: false },
      { title: 'Button', route: { name: 'components-button' }, icon: 'BoldIcon', isBookmarked: false },
      { title: 'Calendar App', route: { name: 'apps-calendar' }, icon: 'CalendarIcon', isBookmarked: true },
      { title: 'Calendar Component', route: { name: 'components-calendar' }, icon: 'CalendarIcon', isBookmarked: false },
      { title: 'Card Actions', route: { name: 'card-action' }, icon: 'CreditCardIcon', isBookmarked: false },
      { title: 'Carousel', route: { name: 'components-carousel' }, icon: 'CopyIcon', isBookmarked: false },
      { title: 'Chartjs', route: { name: 'charts-chartjs' }, icon: 'PieChartIcon', isBookmarked: false },
      { title: 'Chat', route: { name: 'apps-chat' }, icon: 'MessageSquareIcon', isBookmarked: true },
      { title: 'Checkbox', route: { name: 'forms-element-checkbox' }, icon: 'CheckSquareIcon', isBookmarked: false },
      { title: 'Checkout', route: { name: 'apps-e-commerce-checkout' }, icon: 'DollarSignIcon', isBookmarked: false },
      { title: 'Clipboard', route: { name: 'extensions-clipboard' }, icon: 'ClipboardIcon', isBookmarked: false },
      { title: 'Collapse', route: { name: 'components-collapse' }, icon: 'PlusIcon', isBookmarked: false },
      { title: 'Colors', route: { name: 'ui-colors' }, icon: 'DropletIcon', isBookmarked: false },
      { title: 'Coming Soon', route: { name: 'misc-coming-soon' }, icon: 'ClockIcon', isBookmarked: false },
      { title: 'Context Menu', route: { name: 'extensions-context-menu' }, icon: 'MoreVerticalIcon', isBookmarked: false },
      { title: 'Dashboard Analytics', route: { name: 'dashboard-analytics' }, icon: 'ActivityIcon', isBookmarked: false },
      { title: 'Dashboard ECommerce', route: { name: 'dashboard-ecommerce' }, icon: 'ShoppingCartIcon', isBookmarked: false },
      { title: 'Date Time Picker', route: { name: 'extensions-date-time-picker' }, icon: 'ClockIcon', isBookmarked: false },
      { title: 'Drag & Drop', route: { name: 'extensions-drag-and-drop' }, icon: 'CopyIcon', isBookmarked: false },
      { title: 'Dropdown', route: { name: 'components-dropdown' }, icon: 'MoreHorizontalIcon', isBookmarked: false },
      { title: 'Echart', route: { name: 'charts-echart' }, icon: 'PieChartIcon', isBookmarked: false },
      { title: 'Email', route: { name: 'apps-email' }, icon: 'MailIcon', isBookmarked: true },
      { title: 'Embed', route: { name: 'components-embed' }, icon: 'TvIcon', isBookmarked: false },
      { title: 'Error 404', route: { name: 'error-404' }, icon: 'AlertTriangleIcon', isBookmarked: false },
      { title: 'Error', route: { name: 'misc-error' }, icon: 'AlertTriangleIcon', isBookmarked: false },
      { title: 'FAQ', route: { name: 'pages-faq' }, icon: 'HelpCircleIcon', isBookmarked: false },
      { title: 'Feather', route: { name: 'ui-feather' }, icon: 'FeatherIcon', isBookmarked: false },
      { title: 'File Input', route: { name: 'forms-element-file-input' }, icon: 'FileIcon', isBookmarked: false },
      { title: 'Forgot Password V1', route: { name: 'auth-forgot-password-v1' }, icon: 'KeyIcon', isBookmarked: false },
      { title: 'Forgot Password V2', route: { name: 'auth-forgot-password-v2' }, icon: 'KeyIcon', isBookmarked: false },
      { title: 'Form Datepicker', route: { name: 'forms-element-datepicker' }, icon: 'ClockIcon', isBookmarked: false },
      { title: 'Form Layout', route: { name: 'form-layout' }, icon: 'GridIcon', isBookmarked: false },
      { title: 'Form Rating', route: { name: 'forms-element-rating' }, icon: 'StarIcon', isBookmarked: false },
      { title: 'Form Repeater', route: { name: 'form-repeater' }, icon: 'StarIcon', isBookmarked: false },
      { title: 'Form Tag', route: { name: 'forms-element-tag' }, icon: 'TagIcon', isBookmarked: false },
      { title: 'Form Timepicker', route: { name: 'forms-element-timepicker' }, icon: 'ClockIcon', isBookmarked: false },
      { title: 'Form Validation', route: { name: 'form-validation' }, icon: 'CheckCircleIcon', isBookmarked: false },
      { title: 'Form Wizard', route: { name: 'form-wizard' }, icon: 'GitCommitIcon', isBookmarked: false },
      { title: 'Good Table', route: { name: 'table-good-table' }, icon: 'GridIcon', isBookmarked: false },
      { title: 'I18n', route: { name: 'extensions-i18n' }, icon: 'GlobeIcon', isBookmarked: false },
      { title: 'Image', route: { name: 'components-image' }, icon: 'ImageIcon', isBookmarked: false },
      { title: 'Input Group', route: { name: 'forms-element-input-group' }, icon: 'TypeIcon', isBookmarked: false },
      { title: 'Input Mask', route: { name: 'forms-element-input-mask' }, icon: 'TypeIcon', isBookmarked: false },
      { title: 'Input', route: { name: 'forms-element-input' }, icon: 'TypeIcon', isBookmarked: false },
      { title: 'Invoice Add', route: { name: 'apps-invoice-add' }, icon: 'FileTextIcon', isBookmarked: false },
      { title: 'Invoice Edit', route: { name: 'apps-invoice-edit', params: { id: 4987 } }, icon: 'FileTextIcon', isBookmarked: false },
      { title: 'Invoice List', route: { name: 'apps-invoice-list' }, icon: 'FileTextIcon', isBookmarked: false },
      { title: 'Invoice Preview', route: { name: 'apps-invoice-preview', params: { id: 4987 } }, icon: 'FileTextIcon', isBookmarked: false },
      { title: 'Knowledge Base Category', route: { name: 'pages-knowledge-base-category' }, icon: 'InfoIcon', isBookmarked: false, },
      { title: 'Knowledge Base Question', route: { name: 'pages-knowledge-base-question' }, icon: 'InfoIcon', isBookmarked: false, },
      { title: 'Knowledge Base', route: { name: 'pages-knowledge-base' }, icon: 'InfoIcon', isBookmarked: false },
      { title: 'Leaflet', route: { name: 'maps-leaflet' }, icon: 'MapPinIcon', isBookmarked: false },
      { title: 'List Group', route: { name: 'components-list-group' }, icon: 'ListIcon', isBookmarked: false },
      { title: 'Login V1', route: { name: 'auth-login-v1' }, icon: 'LogInIcon', isBookmarked: false },
      { title: 'Login V2', route: { name: 'auth-login-v2' }, icon: 'LogInIcon', isBookmarked: false },
      { title: 'Media Objects', route: { name: 'components-media' }, icon: 'ImageIcon', isBookmarked: false },
      { title: 'Modal', route: { name: 'components-modal' }, icon: 'CopyIcon', isBookmarked: false },
      { title: 'Nav', route: { name: 'components-nav' }, icon: 'CreditCardIcon', isBookmarked: false },
      { title: 'Not Authorized', route: { name: 'misc-not-authorized' }, icon: 'XOctagonIcon', isBookmarked: false },
      { title: 'Overlay', route: { name: 'components-overlay' }, icon: 'CopyIcon', isBookmarked: false },
      { title: 'Pagination Nav', route: { name: 'components-pagination-nav' }, icon: 'HashIcon', isBookmarked: false },
      { title: 'Pagination', route: { name: 'components-pagination' }, icon: 'HashIcon', isBookmarked: false },
      { title: 'Pill Badge', route: { name: 'components-pill-badge' }, icon: 'TagIcon', isBookmarked: false },
      { title: 'Pill', route: { name: 'components-pill' }, icon: 'TagIcon', isBookmarked: false },
      { title: 'Popover', route: { name: 'components-popover' }, icon: 'TagIcon', isBookmarked: false },
      { title: 'Pricing', route: { name: 'pages-pricing' }, icon: 'DollarSignIcon', isBookmarked: false },
      { title: 'Product Details', route: { name: 'apps-e-commerce-product-details', params: { slug: 'apple-watch-series-5-27' } }, icon: 'BoxIcon', isBookmarked: false, },
      { title: 'Profile', route: { name: 'pages-profile' }, icon: 'UserIcon', isBookmarked: false },
      { title: 'Progress', route: { name: 'components-progress' }, icon: 'ChevronsRightIcon', isBookmarked: false },
      { title: 'Quill Editor', route: { name: 'extensions-quill-editor' }, icon: 'TypeIcon', isBookmarked: false },
      { title: 'Radio', route: { name: 'forms-element-radio' }, icon: 'DiscIcon', isBookmarked: false },
      { title: 'Register V1', route: { name: 'auth-register-v1' }, icon: 'UserPlusIcon', isBookmarked: false },
      { title: 'Register V2', route: { name: 'auth-register-v2' }, icon: 'UserPlusIcon', isBookmarked: false },
      { title: 'Reset Password V1', route: { name: 'auth-reset-password-v1' }, icon: 'KeyIcon', isBookmarked: false },
      { title: 'Reset Password V2', route: { name: 'auth-reset-password-v2' }, icon: 'KeyIcon', isBookmarked: false },
      { title: 'Select', route: { name: 'forms-element-select' }, icon: 'AlignCenterIcon', isBookmarked: false },
      { title: 'Shop', route: { name: 'apps-e-commerce-shop' }, icon: 'ArchiveIcon', isBookmarked: false },
      { title: 'Sidebar', route: { name: 'components-sidebar' }, icon: 'SidebarIcon', isBookmarked: false },
      { title: 'Slider', route: { name: 'extensions-slider' }, icon: 'GitCommitIcon', isBookmarked: false },
      { title: 'Spinbutton', route: { name: 'forms-element-spinbutton' }, icon: 'TypeIcon', isBookmarked: false },
      { title: 'Spinner', route: { name: 'components-spinner' }, icon: 'LoaderIcon', isBookmarked: false },
      { title: 'Statistics Cards', route: { name: 'card-statistic' }, icon: 'CreditCardIcon', isBookmarked: false },
      { title: 'Sweet Alert', route: { name: 'extensions-sweet-alert' }, icon: 'BellIcon', isBookmarked: false },
      { title: 'Swiper', route: { name: 'extensions-swiper' }, icon: 'ImageIcon', isBookmarked: false },
      { title: 'Switch', route: { name: 'forms-element-switch' }, icon: 'ToggleRightIcon', isBookmarked: false },
      { title: 'Tab', route: { name: 'components-tab' }, icon: 'CreditCardIcon', isBookmarked: false },
      { title: 'Textarea', route: { name: 'forms-element-textarea' }, icon: 'TypeIcon', isBookmarked: false },
      { title: 'Time', route: { name: 'components-time' }, icon: 'ClockIcon', isBookmarked: false },
      { title: 'Timeline', route: { name: 'components-timeline' }, icon: 'GitCommitIcon', isBookmarked: false },
      { title: 'Toastification', route: { name: 'extensions-toastification' }, icon: 'BellIcon', isBookmarked: false },
      { title: 'Toasts', route: { name: 'components-toasts' }, icon: 'BellIcon', isBookmarked: false },
      { title: 'Todo', route: { name: 'apps-todo' }, icon: 'CheckSquareIcon', isBookmarked: true },
      { title: 'Tooltip', route: { name: 'components-tooltip' }, icon: 'CopyIcon', isBookmarked: false },
      { title: 'Tour', route: { name: 'extensions-tour' }, icon: 'GlobeIcon', isBookmarked: false },
      // { title: 'Tree', route: { name: 'extensions-tree' }, icon: 'GitCommitIcon', isBookmarked: false },
      { title: 'Typography', route: { name: 'ui-typography' }, icon: 'TypeIcon', isBookmarked: false },
      { title: 'Under Maintenance', route: { name: 'misc-under-maintenance' }, icon: 'MonitorIcon', isBookmarked: false },
      { title: 'Users Edit', route: { name: 'apps-users-edit', params: { id: 21 } }, icon: 'UserIcon', isBookmarked: false },
      { title: 'Users List', route: { name: 'apps-users-list' }, icon: 'UserIcon', isBookmarked: false },
      { title: 'Devices List', route: { name: 'apps-devices-list' }, icon: 'UserIcon', isBookmarked: false },
      { title: 'Users View', route: { name: 'apps-users-view', params: { id: 21 } }, icon: 'UserIcon', isBookmarked: false },
      { title: 'Vue Select', route: { name: 'extensions-vue-select' }, icon: 'AlignCenterIcon', isBookmarked: false },
      { title: 'Wishlist', route: { name: 'apps-e-commerce-wishlist' }, icon: 'HeartIcon', isBookmarked: false },
    ],
  },
  files: {
    key: 'file_name',
    data: [
      {
        file_name: "Joe's CV",
        from: 'Stacy Watson',
        icon: require('@/assets/images/icons/doc.png'),
        size: '1.7 mb',
      },
      {
        file_name: 'Passport Image',
        from: 'Ben Sinitiere',
        icon: require('@/assets/images/icons/jpg.png'),
        size: ' 52 kb',
      },
      {
        file_name: 'Questions',
        from: 'Charleen Patti',
        icon: require('@/assets/images/icons/doc.png'),
        size: '1.5 gb',
      },
      {
        file_name: 'Parenting Guide',
        from: 'Doyle Blatteau',
        icon: require('@/assets/images/icons/doc.png'),
        size: '2.3 mb',
      },
      {
        file_name: 'Class Notes',
        from: 'Gwen Greenlow',
        icon: require('@/assets/images/icons/doc.png'),
        size: ' 30 kb',
      },
      {
        file_name: 'Class Attendance',
        from: 'Tom Alred',
        icon: require('@/assets/images/icons/xls.png'),
        size: '52 mb',
      },
      {
        file_name: 'Company Salary',
        from: 'Nellie Dezan',
        icon: require('@/assets/images/icons/xls.png'),
        size: '29 mb',
      },
      {
        file_name: 'Company Logo',
        from: 'Steve Sheldon',
        icon: require('@/assets/images/icons/jpg.png'),
        size: '1.3 mb',
      },
      {
        file_name: 'Crime Rates',
        from: 'Sherlock Holmes',
        icon: require('@/assets/images/icons/xls.png'),
        size: '37 kb',
      },
      {
        file_name: 'Ulysses',
        from: 'Theresia Wrenne',
        icon: require('@/assets/images/icons/pdf.png'),
        size: '7.2 mb',
      },
      {
        file_name: 'War and Peace',
        from: 'Goldie Highnote',
        icon: require('@/assets/images/icons/pdf.png'),
        size: '10.5 mb',
      },
      {
        file_name: 'Vedas',
        from: 'Ajay Patel',
        icon: require('@/assets/images/icons/pdf.png'),
        size: '8.3 mb',
      },
      {
        file_name: 'The Trial',
        from: 'Sirena Linkert',
        icon: require('@/assets/images/icons/pdf.png'),
        size: '1.5 mb',
      },
    ],
  },
  contacts: {
    key: 'name',
    data: [
      {
        img: require('@/assets/images/portrait/small/avatar-s-4.jpg'),
        name: 'Rena Brant',
        email: 'nephrod@preany.co.uk',
        time: '21/05/2019',
      },
      {
        img: require('@/assets/images/portrait/small/avatar-s-2.jpg'),
        name: 'Mariano Packard',
        email: 'seek@sparaxis.org',
        time: '14/01/2018',
      },
      {
        img: require('@/assets/images/portrait/small/avatar-s-24.jpg'),
        name: 'Risa Montufar',
        email: 'vagary@unblist.org',
        time: '10/08/2019',
      },
      {
        img: require('@/assets/images/portrait/small/avatar-s-15.jpg'),
        name: 'Maragaret Cimo',
        email: 'designed@insanely.net',
        time: '01/12/2019',
      },
      {
        img: require('@/assets/images/portrait/small/avatar-s-7.jpg'),
        name: 'Jona Prattis',
        email: 'unwieldable@unblist.org',
        time: '21/05/2019',
      },
      {
        img: require('@/assets/images/portrait/small/avatar-s-5.jpg'),
        name: 'Edmond Chicon',
        email: 'museist@anaphyte.co.uk',
        time: '15/11/2019',
      },
      {
        img: require('@/assets/images/portrait/small/avatar-s-25.jpg'),
        name: 'Abbey Darden',
        email: 'astema@defectively.co.uk',
        time: '07/05/2019',
      },
      {
        img: require('@/assets/images/portrait/small/avatar-s-10.jpg'),
        name: 'Seema Moallankamp',
        email: 'fernando@storkish.co.uk',
        time: '13/08/2017',
      },
      {
        img: require('@/assets/images/portrait/small/avatar-s-2.jpg'),
        name: 'Charleen Warmington',
        email: 'furphy@cannibal.net',
        time: '11/08/1891',
      },
      {
        img: require('@/assets/images/portrait/small/avatar-s-25.jpg'),
        name: 'Geri Linch',
        email: 'insignia@markab.org',
        time: '18/01/2015',
      },
      {
        img: require('@/assets/images/portrait/small/avatar-s-23.jpg'),
        name: 'Shellie Muster',
        email: 'maxillary@equalize.co.uk',
        time: '26/07/2019',
      },
      {
        img: require('@/assets/images/portrait/small/avatar-s-20.jpg'),
        name: 'Jesenia Vanbramer',
        email: 'hypotony@phonetist.net',
        time: '12/09/2017',
      },
      {
        img: require('@/assets/images/portrait/small/avatar-s-23.jpg'),
        name: 'Mardell Channey',
        email: 'peseta@myrica.com',
        time: '11/11/2019',
      },
    ],
  },
}
/* eslint-enable */
