// eslint-disable-next-line strict

export default [
  {
    title: 'Dashboards',
    icon: 'HomeIcon',
    // tag: '2',
    tagVariant: 'light-warning',
    // children: [
    //   // {
    //   //   title: 'eCommerce',
    //   //   route: 'dashboard-ecommerce',
    //   // },
    //   // {
    //   //   title: 'Analytics',
    //   //   route: 'dashboard-analytics',
    //   // },
    //   {
    //     title: 'Factory',
    //     route: 'dashboard-factory',
    //     params: { siteId: 1 },
    //   },
    // ],
    children: [],
  },
]
