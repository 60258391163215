export default [
  {
    header: 'Settings',
    icon: 'SettingsIcon',
    children: [
      {
        title: 'Faults',
        icon: 'SlidersIcon',
        children: [
          {
            title: 'List',
            route: 'settings-faultcode-list',
          },
        ],
      },
      {
        title: 'Rules',
        icon: 'SlidersIcon',
        children: [
          {
            title: 'List',
            route: 'settings-rules-list',
          },
        ],
      },
      {
        title: 'Training',
        icon: 'SlidersIcon',
        children: [
          {
            title: 'List',
            route: 'settings-training-list',
          },
        ],
      },
      {
        title: 'Maintenance Schedule',
        icon: 'SlidersIcon',
        children: [
          {
            title: 'List',
            route: 'settings-maintenance-list',
          },
        ],
      },
    ],
  },
]
