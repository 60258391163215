<template>
  <div class="navbar-container main-menu-content">
    <horizontal-nav-menu-items :items="navMenuItemsModified" />
  </div>
</template>

<script>
import navMenuItems from '@/navigation/horizontal'
import settingsItems from '@/navigation/horizontal/settings-and-pages'
import { ref } from '@vue/composition-api'
import { getSiteRoutes, isAllCustomerUser, allowDeviceManagement, allowFactoryManagement } from '@/api/auth/utils'
import HorizontalNavMenuItems from './components/horizontal-nav-menu-items/HorizontalNavMenuItems.vue'

export default {
  components: {
    HorizontalNavMenuItems,
  },
  setup() {
    const canUserManageCustomer = isAllCustomerUser()
    const allowDevice = allowDeviceManagement()
    const allowFactory = allowFactoryManagement()

    if (canUserManageCustomer) {
      const appsIndex = navMenuItems.findIndex(item => item.header === 'Apps')
      navMenuItems[appsIndex].children.push({
        title: 'Customers',
        icon: 'UsersIcon',
        children: [
          {
            title: 'List',
            route: 'apps-customers-list',
          },
        ],
      })
      navMenuItems[appsIndex].children.push({
        title: 'Enquiry Log',
        icon: 'TwitchIcon',
        route: 'apps-enquiry',
      })
    }
    if (allowDevice) {
      const appsIndex = navMenuItems.findIndex(item => item.header === 'Apps')
      navMenuItems[appsIndex].children.push({
        title: 'Device',
        icon: 'CpuIcon',
        children: [
          {
            title: 'List',
            route: 'apps-devices-list',
          },
          {
            title: 'Attach',
            route: 'apps-devices-swap',
          },
          {
            title: 'Update',
            route: 'apps-devices-update',
          },
        ],
      })
    }

    if (allowFactory) {
      const appsIndex = navMenuItems.findIndex(item => item.header === 'Apps')
      navMenuItems[appsIndex].children.push({
        title: 'Site / Building / Line​',
        icon: 'BriefcaseIcon',
        children: [
          {
            title: 'List',
            route: 'apps-factory-list',
          },
        ],
      })
    }

    const navMenuItemsModified = ref([...navMenuItems, ...canUserManageCustomer ? settingsItems : []])
    console.log('navMenuItemsModified', navMenuItemsModified)
    const routes = getSiteRoutes()
    const siteList = routes.map(element => ({
      title: element.siteName,
      route: element.name,
    }))
    const dashboardIndex = navMenuItemsModified.value.findIndex(item => item.header === 'Dashboards')
    navMenuItemsModified.value[dashboardIndex].children = siteList
    navMenuItemsModified.value[dashboardIndex].tag = siteList.length
    return {
      navMenuItemsModified,
    }
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/horizontal-menu.scss";
</style>
